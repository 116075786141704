<template>
  <v-container class="pa-0 ma-0 mr-0" style="max-width:100%;">
    <v-layout id="equipment-manager">
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-layout text-xs-right>
          <v-flex xs12 class="pa-0 ma-2 mr-0"  v-if="isAdmin">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    class="normalize-font"
                    small
                    dark
                    fab
                    icon
                    top
                    right
                    @click="onOpenImportEquipmentsDialog"
                    v-on="on"
                >
                  <v-icon style="display: inline-flex" small>attach_file</v-icon>
                </v-btn>
              </template>
              <span>Import</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    small
                    dark
                    fab
                    top
                    icon
                    right
                    color="primary"
                    @click="exportEquipmentsAsExcelFile"
                    v-on="on"
                >
                  <v-icon style="display: inline-flex">import_export</v-icon>
                </v-btn>
              </template>
              <span>Export</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    small
                    dark
                    fab
                    top
                    icon
                    right
                    color="primary"
                    @click="addEquipment"
                    v-on="on"
                >
                  <v-icon style="display: inline-flex">add</v-icon>
                </v-btn>
              </template>
              <span>Erstellen</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
        <v-card-title>
          <v-layout>
            <v-flex md3 xs12>
              <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Suche"
                  placeholder="Anbieter-Produkt Nr, Seriennnummer, ..."
                  single-line
                  hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-data-table
            style="padding: 0 15px!important;"
            :headers="headers"
            :search="search"
            :items="getUntrashedElements(equipments)"
            :pagination.sync="pagination"
            :rows-per-page-items="[5, 10, 25, { text: 'Alle', value: -1 }]"
            rows-per-page-text="Zeilen pro Seite"
            class="elevation-0"
        >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                <v-btn
                    small
                    icon
                    flat
                    right
                    @click="
                  changeVisibility(props.item.equipmentId, props.item.active)
                "
                    color="primary"
                    :disabled="!isAdmin"
                >
                <span v-if="props.item.active === 1">
                  <v-icon small>visibility_off</v-icon>
                </span>
                  <span v-if="props.item.active === 0 || !props.item.active">
                  <v-icon small>visibility</v-icon>
                </span>
                </v-btn>
              </td>
              <td class="text-xs-left">{{ props.item.equipmentId }}</td>
              <td class="text-xs-left">{{ props.item.createdAt | formatDate }}</td>
              <td class="text-xs-left">{{ props.item.updatedAt | formatDate }}</td>
              <td class="text-xs-left">{{ props.item.providerProductNumber }}</td>
              <td class="text-xs-left">{{ props.item.serialNumber }}</td>
              <td class="text-xs-left">{{ props.item.type }}</td>
              <td class="text-xs-left">{{ props.item.propulsion }}</td>
              <td class="text-xs-left">{{ props.item.constructionYear }}</td>
              <td class="text-xs-left">{{ props.item.mastType }}</td>
              <td class="text-xs-left">{{ props.item.liftingHeight | meter }}</td>
              <td class="text-xs-left">{{ props.item.loadCapacity | weight }}</td>
              <td class="text-xs-left">{{ props.item.price }} €</td>
              <td class="text-xs-left text-md-left actions-cell">
                <v-layout v-if="isAdmin">
                  <v-flex>
                    <v-btn
                        right
                        small
                        icon
                        flat
                        @click="editEquipment(props.item.equipmentId)"
                        color="primary"
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn
                        right
                        small
                        icon
                        flat
                        @click="onDuplicateEquipment(props.item.equipmentId)"
                        color="primary"
                    >
                      <v-icon small>content_copy</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn
                        right
                        small
                        icon
                        flat
                        @click="setReservedStatus(props.item.equipmentId)"
                        color="primary"
                    >
                      <v-icon v-if="isReservedActive(props.item.reservedUntil)" small>lock_clock</v-icon>
                      <v-icon v-else small>lock_open</v-icon>

                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn
                        right
                        small
                        icon
                        flat
                        @click="setFundableStatus(props.item.equipmentId)"
                        color="primary"
                    >
                      <v-icon v-if="props.item.isFundable" small>credit_card</v-icon>
                      <v-icon v-else small>credit_card_off</v-icon>

                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn
                        right
                        small
                        icon
                        flat
                        @click="openMoveEqToTrashDialog(props.item.equipmentId)"
                        color="error"
                    >
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout v-else>
                  <v-flex>
                    <v-btn
                        right
                        small
                        icon
                        flat
                        @click="editEquipment(props.item.equipmentId)"
                        color="primary"
                    >
                      <v-icon small>description</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </td>
            </template>
        </v-data-table>
        <!-- DELETE DIALOG -->
        <EquipmentDeleteDialog></EquipmentDeleteDialog>
        <!-- EDIT DIALOG -->
        <EquipmentForm
            v-model="dialog"
            :equipment="selectedEquipment"
            :showDialog="dialog"
        ></EquipmentForm>
        <v-dialog v-model="reservedDialog" max-width="600">
          <EquipmentReservedDialogForm
              :equipment="selectedEquipment"
          ></EquipmentReservedDialogForm>
        </v-dialog>
        <EquipmentImportDialog></EquipmentImportDialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapState, mapActions, mapMutations} from "vuex";
import EquipmentForm from "@/components/EquipmentForm.vue";
import EquipmentReservedDialogForm from "@/components/EquipmentReservedDialog.vue";
import EquipmentServices from "../../services/equipment.services";
import {EventBus} from "../../event-bus";
import HelperFunctions from "@/utils/helper-functions";
import EquipmentImportDialog from "@/components/_equipment/ImportDialog.vue";
import EquipmentDeleteDialog from "@/components/_equipment/DeleteDialog.vue";

export default {
  name: "equipmentManagement",
  data: function () {
    return {
      pagination: {
        sortBy: "equipmentId",
        rowsPerPage: 10
      },
      search: "",
      headers: [
        {text: "Aktiv", value: "active", width: "1%"},
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "equipmentId",
          width: "1%"
        },
        {text: "Erstelldatum", value: "createdAt", width: "1%", type: "date"},
        {text: "Aktualisiert", value: "updatedAt", width: "1%", type: "date"},
        {text: "AnbProdId", value: "AProdID", width: "1%", type: "string"},
        {text: "Seriennr.", value: "serialNumber", width: "1%", type: "string"},
        {text: "Typen Beschreibung", value: "type", width: "1%", type: "string"},
        {text: "Antrieb", value: "propulsion", width: "1%", type: "string"},
        {text: "Baujahr", value: "constructionYear", sortable: true, width: "1%"},
        {text: "Masttyp", value: "mastType", width: "1%", type: "string"},
        {text: "Hubhöhe", value: "liftingHeight", width: "1%", type: "meter"},
        {text: "Tragfähigkeit", value: "loadCapacity", width: "1%", type: "mass"},
        {text: "Preis (€)", value: "price", width: "1%", type: "string"},
        {text: "", value: "actions", sortable: false, width: "1%", type: "string"}
      ],
      dialogStatus: false,
      deleteDialog: false,
      importDialog: false,
      reservedDialog: false,
      equipmentId: null,
      equipment: null,
      isAdmin: false,
      selectedEquipment: this.getEmptyEquipment(),
      file: "",
      response: "",
      infoColor: "primary",
    };
  },
  components: {
    EquipmentDeleteDialog,
    EquipmentImportDialog,
    EquipmentForm,
    EquipmentReservedDialogForm,
  },
  mounted() {
    this.isAdmin = this.user.isAdmin;
    this.unsetSearchParameters();
    this.resetQuery();
    this.getEquipmentData();
    EventBus.$on('onCloseReservedDialog', (rp) => {
      this.reservedDialog = false;
      if (rp)
        this.getEquipmentData();
    })
  },
  computed: {
    ...mapState(["equipments", "accessToken", "user"]),
    dialog: {
      get: function () {
        return this.dialogStatus;
      },
      set: function (value) {
        this.dialogStatus = value;
      }
    }
  },
  methods: {
    ...HelperFunctions,
    ...mapMutations({resetQuery: "resetQuery"}),
    ...mapActions(["getEquipmentData", "unsetSearchParameters"]),
    addEquipment() {
      this.selectedEquipment = this.getEmptyEquipment();
      EventBus.$emit("openDialog", true);
      EventBus.$emit("onSelectEquipment", this.selectedEquipment);
    },
    onOpenImportEquipmentsDialog() {
      EventBus.$emit("openEqImportDialog", true);
    },
    editEquipment(equipmentId) {
      this.selectedEquipment = this.equipments.find(
          e => e.equipmentId === equipmentId
      );
      EventBus.$emit("openDialog", true);
      EventBus.$emit("onSelectEquipment", this.selectedEquipment);
    },
    openMoveEqToTrashDialog(equipmentId) {
      EquipmentServices.getEquipmentById(this.accessToken, equipmentId)
          .then(response => {
                this.selectedEquipment = response.data;
                EventBus.$emit("openMoveEqTrashDialog", {
                  equipment: this.selectedEquipment,
                  dialog: true
                });
              }
          );
    },
    changeVisibility(equipmentId, active) {
      const visibility = !active ? "inaktiv" : "aktiv";
      this.loading(true);
      EquipmentServices.patchEquipmentById(
          this.accessToken,
          equipmentId,
          {
            active: active === 1 ? 0 : 1
          }
      )
          .then(() => {
            this.initiateSuccessInfo("Equipment ist nun " + visibility, true)
            this.reloadEquipmentsWithDelay(2000)
          })
          .catch(() => {
            this.initiateErrorInfo("Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.")
          });
    },
    setReservedStatus(equipmentId) {
      this.reservedDialog = true;
      this.getReservedFor(equipmentId);
    },
    setFundableStatus(equipmentId) {
      if (this.isFundable === "undefined") this.isFundable = false;
      this.isFundable = !this.isFundable;
      this.loading(true);
      EquipmentServices.patchEquipmentById(this.accessToken, equipmentId, {isFundable: this.isFundable})
          .then(() => {
            this.initiateSuccessInfo(`Equipment ist ${this.isFundable ? "nun finanzierbar!" : " nicht mehr finanzierbar!"}`, true)
            this.reloadEquipmentsWithDelay(2000)
          })
          .catch(() => {
            this.initiateErrorInfo("Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.")
          });
    },
    onDuplicateEquipment(equipmentId) {
      const equipment = this.equipments.find(e => e.equipmentId === equipmentId);
      equipment.serialNumber = "";
      equipment.providerProductNumber = "";
      equipment.AProdID = "";
      equipment.equipmentId = ""
      equipment.createdAt = new Date(Date.now())
      equipment.updatedAt = new Date(Date.now())
      EventBus.$emit("openDialog", true);
      EventBus.$emit("onSelectEquipment", equipment);
    },
    getReservedFor(equipmentId) {
      EquipmentServices.getReservedInformation(this.accessToken, equipmentId)
          .then((response) => {
            const rp = response.data;
            const name = rp.reservedFor;
            let reservedUntil;
            if (rp.reservedUntil) {
              reservedUntil = new Date(rp.reservedUntil).toISOString().substring(0, 10);
            } else {
              reservedUntil = new Date(Date.now()).toISOString().substring(0, 10);
            }
            EventBus.$emit("openReservedDialog", {
              id: equipmentId,
              name,
              reservedUntil
            });
          })
    },
    exportEquipmentsAsExcelFile() {
      this.loading(true);
      this.initiateSuccessInfo("Equipments wurden erfolgreich exportiert!")
      const exportData = this.equipments.map(e => ({
        ID: e.equipmentId,
        Erstelldatum: this.formatDateTime(e.createdAt),
        Aktualisiert: this.formatDateTime(e.updatedAt),
        AnbProdID: e.AProdID,
        Bauart: e.constructionType,
        Antriebsart: e.propulsion,
        Typ: e.type,
        SerialNr: e.serialNumber,
        Baujahr: e.constructionYear,
        Betriebsstunden: e.operatingHours,
        Zusatzhydraulik: this.formatEmptyValue(e.additionalHydraulic),
        Anbaugeraet: this.formatEmptyValue(e.attachmentUnit),
        Masttyp: e.mastType,
        Hubhoehe: e.liftingHeight,
        Bauhoehe: e.height,
        Fahrerschutzdach: this.formatEmptyValue(e.driversModule),
        Preis: e.price
      }));
      const timestamp = new Date(Date.now());
      this.exportEquipmentFromJSON(exportData, "Equipments_" + timestamp.getTime())
      this.reloadEquipmentsWithDelay(2000)
    }
  }
};
</script>

<style scoped>
/deep/ table.v-table tbody td,
/deep/ table.v-table tbody th,
/deep/ table.v-table thead td,
/deep/ table.v-table thead th {
  padding: 0 8px !important;
}
</style>
