<template>
  <div class="results-list">
    <v-card
        style="max-width: 200px; margin-left: auto; margin-right: auto; text-align: center"
        v-if="getActiveElements(equipments).length === 0"
    >
      <v-card-text>
        Keine Ergebnisse gefunden
      </v-card-text>
    </v-card>
    <div v-for="equipment in equipmentsPerPage" :key="equipment.equipmentId">
      <v-card
          class="mb-3 cursor"
          v-if="(equipment.active === 0 || equipment.active === null) && !isReservedActive(equipment.reservedUntil)"
      >
        <v-card-title class="pa-0">
          <v-layout wrap row>
            <v-flex md3 xs12 sm4 class="">
              <vueper-slides
                  :slide-ratio="0.76"
                  :infinite="false"
                  disable-arrows-on-edges
                  v-if="equipment.image && equipment.image.image">
                <vueper-slide
                    v-for="(slide, i) in filteredAndSortedImages(images, equipment)"
                    :key="i"
                    :image="appendBaseUrl(slide.image)">
                </vueper-slide>
              </vueper-slides>
              <v-img
                  v-else
                  class="white--text"
                  @click="onSelectEquipment(equipment)"
                  :src="require('@/assets/placeholder-image.jpg')"
                  :aspect-ratio="1.333"
              >
                <template v-slot:placeholder>
                  <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                  </v-layout>
                </template>
              </v-img>
              <ShareAndFavorite style="text-align: center;" :equipment="equipment"></ShareAndFavorite>
            </v-flex>
            <v-flex md9 xs12 sm8 class="mt-2">
              <v-layout @click="onSelectEquipment(equipment)" class="px-3 pb-0" wrap row justify-space-between>
                <v-flex class="subheading mb-0 pb-0" md9 xs9 sm8>
                  <span class="linde-font linde-color linde-bold">{{
                      equipment.type
                    }}</span>
                </v-flex>
                <v-flex d-flex class="mb-0 pb-0 text-lg-left">
                  <span
                      class="subheading text-md-right text-sm-right text-xs-right linde-font linde-color linde-bold"
                  >€ {{ formatNumbers(equipment.price) }}
                          <span
                              class="body-1" style="color: #636363; font-family: linde;">*</span>
                  </span>
                </v-flex>
              </v-layout>
              <v-layout class="px-3 pb-2" wrap row justify-space-between>
                <v-flex md10 xs9 sm9 @click="onSelectEquipment(equipment)">
                  <span class="subheading linde-font mb-1 "
                  >{{ equipment.constructionType }}
                    {{
                      equipment.additionalConstructionType
                          ? "/ " + equipment.additionalConstructionType.replace("/", "")
                          : ""
                    }}</span
                  >
                </v-flex>
                <!--<v-flex d-flex>
                    <ShareAndFavorite style="text-align: right; margin-top: -10px" :equipment="equipment"></ShareAndFavorite>
                </v-flex> -->
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.constructionYear"
                      label="Baujahr:"
                      :value="equipment.constructionYear"
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.constructionYear ||
                        equipment.constructionYear === 0
                    "
                      label="Baujahr:"
                      value="-"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.liftingHeight"
                      label="Hubhöhe:"
                      :value="equipment.liftingHeight"
                      unit="mm"
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.liftingHeight || equipment.liftingHeight === 0
                    "
                      label="Hubhöhe:"
                      value="-"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.loadCapacity"
                      label="Traglast:"
                      :value="equipment.loadCapacity"
                      unit="kg"
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.loadCapacity || equipment.loadCapacity === 0
                    "
                      label="Traglast:"
                      value="-"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.operatingHours"
                      label="Betriebsstunden:"
                      :value="formatNumbers(equipment.operatingHours)"
                      unit="h"
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.operatingHours ||
                        equipment.operatingHours === 0
                    "
                      label="Betriebsstunden:"
                      value="Auf Anfrage"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.height"
                      label="Bauhöhe:"
                      :value="equipment.height"
                      unit="mm"
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.height ||
                        equipment.height === 0
                    "
                      label="Bauhöhe:"
                      value="-"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.additionalHydraulic"
                      label="Hydraulik:"
                      :value="equipment.additionalHydraulic"
                      unit=""
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.additionalHydraulic ||
                        equipment.additionalHydraulic === 0
                    "
                      label="Hydraulik:"
                      value="-"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.mastType"
                      label="Mast:"
                      :value="equipment.mastType"
                      unit=""
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.mastType ||
                        equipment.mastType === 0
                    "
                      label="Mast:"
                      value="-"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.attachmentUnit"
                      label="Anbaugerät:"
                      :value="equipment.attachmentUnit"
                      unit=""
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.attachmentUnit ||
                        equipment.attachmentUnit === 0
                    "
                      label="Anbaugerät:"
                      value="-"
                  />
                </v-flex>
                <v-flex v-if="accessToken" @click="onSelectEquipment(equipment)" class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.serialNumber"
                      label="Seriennr.:"
                      :value="equipment.serialNumber"
                      unit=""
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.serialNumber ||
                        equipment.serialNumber === 0
                    "
                      label="Seriennr:"
                      value="-"
                  />
                </v-flex>
                <v-flex v-if="accessToken" @click="onSelectEquipment(equipment)" class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.providerProductNumber"
                      label="Anb. Prod.-Nr.:"
                      :value="equipment.providerProductNumber"
                      unit=""
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.providerProductNumber ||
                        equipment.providerProductNumber === ''"
                      label="Anb. Prod.-Nr.:"
                      value="-"
                  />
                </v-flex>
                <v-flex v-if="!accessToken" @click="onSelectEquipment(equipment)" class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      v-if="equipment.providerProductNumber"
                      label="Anb. Prod.-Nr.:"
                      :value="equipment.providerProductNumber"
                      unit=""
                  />
                  <DescriptionValueDisplay
                      v-if="
                      !equipment.providerProductNumber ||
                        equipment.providerProductNumber === ''"
                      label="Anb. Prod.-Nr.:"
                      value="-"
                  />
                </v-flex>
                <v-flex @click="onSelectEquipment(equipment)" class="my-0 py-0" md7 sm12 xs12>
                  <span
                      class=""
                      style="font-size: 10px;color: #a2a2a2; font-family: linde;position: absolute;right: 15px;bottom: 0;">
                    * Preis exkl. MwSt.
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>
    </div>
    <div class="text-xs-center">
      <v-pagination
          v-if="getActiveElements(equipments).length > 0"
          v-model="actualPage"
          :length="pages"
          @input="storeAndScroll"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import ShareAndFavorite from "@/components/ShareAndFavorite";
import HelperFunctions from "@/utils/helper-functions";
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "ResultsList",
  data() {
    return {
      actualPage: 1,
      perPage: 20,
    };
  },
  components: {
    ShareAndFavorite,
    DescriptionValueDisplay,
    VueperSlide,
    VueperSlides
  },
  mounted() {
    this.actualPage = this.$store.state.settings.actualPage || 1;
  },
  computed: {
    ...mapState(["equipments", "constructionYearSpan", "accessToken", "images"]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
    equipmentsPerPage: {
      get: function () {
        return this.getActiveElements(this.equipments).slice((this.actualPage - 1) * this.perPage, this.actualPage * this.perPage);
      }
    },
    pages() {
      return Math.ceil(this.getActiveElements(this.equipments).length / this.perPage);
    },
  },
  methods: {
    ...HelperFunctions,
    onSelectEquipment(equipment) {
      equipment.image = "";
      equipment.imagesReal = [];
      this.$store.commit("setSelectedEquipment", equipment);
      this.$router.push("detail/" + equipment.equipmentId);
    },
    storeAndScroll() {
      this.$store.commit("updateActualPage", this.actualPage);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.results-list li::before {
  content: none;
}

.v-pagination ::before {
  content: "";
}

a {
  color: #42b983;
}

</style>
