<template>
  <div class="equipment-images">
    <v-layout row wrap>
      <v-flex md12 sm6 xs12>
        <v-layout>
          <v-flex md12 sm12 xs12>
            <v-img
              position="center center"
              contain
              v-if="image"
              :src="appendBaseUrl(image)"
              aspect-ratio="1.333"
            >
              <v-layout
                v-if="equipment.sampleImage"
                pa-2
                column
                fill-height
                class="lightbox black--text"
              >
              </v-layout>
              <v-layout pa-3
                        column
                        fill-height>
                <v-flex>
                  <ShareAndFavorite :equipment="equipment"></ShareAndFavorite>
                </v-flex>
              </v-layout>
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>

            <v-img
              v-else
              :src="require('@/assets/placeholder-image.jpg')"
              aspect-ratio="1"
            >
              <v-layout v-if="equipment.sampleImage" pa-2 column fill-height class="lightbox black--text">
              </v-layout>
              <v-layout pa-3 column fill-height>
                <ShareAndFavorite :equipment="equipment"></ShareAndFavorite>
              </v-layout>
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex md12 sm6 xs12>
        <v-layout row wrap v-if="images.length > 0">
          <v-flex v-for="(image, index) in images" :key="index" md4 sm3 xs2>
            <v-img
              v-if="image && image.image"
              contain
              position="center center"
              :src="appendBaseUrl(image.image)"
              @click="setImage(index)"
              style="cursor: pointer"
              aspect-ratio="1.333"
            >
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex class="ml-2 ">
            <EquipmentDetailsSeller
              :equipment="equipment"
            ></EquipmentDetailsSeller>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import EquipmentDetailsSeller from "@/components/EquipmentDetailsSeller.vue";
import ImageServices from "../services/image.service";
import HelperFunctions from "@/utils/helper-functions";
import ShareAndFavorite from "@/components/ShareAndFavorite.vue";

export default {
  name: "EquipmentImages",
  data() {
    return {
      images: [],
      image: "",
      ready: false,
      favorite: false,
    };
  },
  props: {
    equipment: ""
  },
  mounted() {
    ImageServices.getImagesByEquipmentId(
      this.$store.state.accessToken,
      this.equipment.equipmentId
    )
        .then(res => {
      let images = res.data;
      images = this.filteredAndSortedImages(images, this.equipment);
      if (images.length > 0) {
        for (const img of images) {
          if (img.image.includes("files")) {
            img.image = img.image.replace("files/", "");
          }
        }
        this.image = images[0].image;
        this.images = images;
      }
    });
    this.favorite = this.isFavorite(this.equipment.equipmentId);
  },
  components: {
    ShareAndFavorite,
    EquipmentDetailsSeller
  },
  computed: {
    assignedImage: {
      get: function() {
        return this.image;
      },
      set: function(value) {
        this.image = this.images[value];
      }
    },
  },
  methods: {
    ...HelperFunctions,
    setImage(index) {
      this.image = this.images[index].image;
    },
  }
};
</script>
