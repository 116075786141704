<template>
  <v-icon
      :class="getColorClass(status)"
      class="circle-icon"
      small
  >circle</v-icon>
</template>

<script>

import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "colored-status-indicator",
  props: {
    status: '',
  },
  methods: {
    ...HelperFunctions,
  },
}

</script>
