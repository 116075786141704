<template>
  <v-container class="pa-0 ma-0 mr-0" style="max-width:100%;">
    <v-layout>
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-layout text-xs-right>
          <v-flex xs12 class="pa-0 ma-2 mr-0">
            <v-dialog
              v-model="newUserDialog"
              persistent
              max-width="600px"
              transition="dialog-bottom-transition"
            >
              <v-btn slot="activator" small dark fab top right color="primary">
                <v-icon style="display: inline-flex" small>add</v-icon>
              </v-btn>
              <v-card>
                <v-toolbar dark color="primary px-3">
                  <v-toolbar-title>Benutzer anlegen</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="valid" ref="form">
                    <v-container grid-list-xs>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-text-field
                            label="*Unternehmen"
                            required
                            :rules="rules.nameRules"
                            v-model="userdata.company"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            label="*Vorname"
                            required
                            :rules="rules.nameRules"
                            v-model="userdata.firstName"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            label="*Nachname"
                            required
                            :rules="rules.nameRules"
                            v-model="userdata.lastName"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 v-if="superUser == 1">
                          <v-select
                            :items="['Admin', 'Verkäufer']"
                            v-model="role"
                            label="Benutzerrolle"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            label="*Email"
                            persistent-hint
                            :rules="rules.emailRules"
                            v-model.trim="userdata.email"
                            hint="An diese E-Mail wird das Initial-Passwort gesendet."
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                              label="*Telefon"
                              persistent-hint
                              :rules="rules.phoneRules"
                              v-model.trim="userdata.phone"
                              hint="Diese wird für den Ausdruck der PDF verwendet."
                              required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                          <v-text-field
                            label="*Initial-Password"
                            v-model.lazy="password"
                            :rules="rules.passwordRules"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs-6>
                          <v-btn
                            class="normalize-font"
                            @click="generatePassword()"
                            >Neues Passwort erzeugen</v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <v-spacer></v-spacer>
                      <small>*indiziert Pflichtfelder</small>
                    </v-flex>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="normalize-font mb-2"
                    flat
                    @click.native="newUserDialog = false"
                    >Abbrechen</v-btn
                  >
                  <v-btn
                    class="normalize-font mb-2 mr-2"
                    color="primary"
                    @click.native="addUser()"
                    >Benutzer anlegen</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
        <v-data-table
          style="padding: 10px !important;"
          witdth
          :headers="headers"
          :items="users"
          item-key="id"
          :pagination.sync="pagination"
          :rows-per-page-items="[5, 10, 25, { text: 'Alle', value: -1 }]"
          rows-per-page-text="Zeilen pro Seite"
          class="elevation-0 pa-0 ma-0 fixed-actions-table"
        >
          <template slot="items" slot-scope="props">
            <tr
              :active="props.selected"
              @click="props.selected = !props.selected"
            >
              <td>{{ props.item.id }}</td>
              <td class="text-xs-left">{{ props.item.company }}</td>
              <td class="text-xs-left">{{ props.item.firstName }}</td>
              <td class="text-xs-left">{{ props.item.lastName }}</td>
              <td class="text-xs-left">
                {{ props.item.isAdmin ? "Admin" : "Verkäufer" }}
              </td>
              <td class="text-xs-left">{{ props.item.email }}</td>
              <td class="text-xs-left">{{ props.item.phone }}</td>
              <td class="actions-cell">
                <v-layout>
                  <v-flex class="px-0 mx-0">
                    <v-btn
                      right
                      small
                      flat
                      color="primary"
                      icon
                      @click="openEditUserDialog(props.item)"
                    >
                      <v-icon
                        v-if="
                          (superUser && superUser == 1) ||
                            (user.isAdmin && !props.item.isAdmin)
                        "
                        small
                        >edit</v-icon
                      >
                      <v-icon v-else small>visibility</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex
                    v-if="
                      (superUser && superUser == 1) ||
                        (user.isAdmin && !props.item.isAdmin)
                    "
                    class="px-0 mx-0"
                  >
                    <v-btn
                      small
                      flat
                      icon
                      @click="openDeleteDialog(props.item)"
                      color="error"
                      dark
                    >
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </td>
              <td class="text-xs-left"></td>
              <td class="text-xs-left"></td>
              <!--<td class="text-xs-left">
                                <v-btn right small flat color="primary"
                                       @click="changePasswordRequest(props.item.email)">Passwort zurücksetzen
                                </v-btn>
                            </td> -->
            </tr>
          </template>
        </v-data-table>
        <v-dialog v-model="deleteDialog" width="500">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title class="linde-font px-3"
                >Benutzer löschen</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              Möchten Sie wirklich {{ deleteItem.firstName }}
              {{ deleteItem.lastName }} löschen?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="normalize-font mb-2"
                flat
                @click="deleteDialog = false"
              >
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                class="normalize-font mb-2 mr-2"
                @click="deleteUser(deleteItem.id)"
              >
                Löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="editUserDialog"
          hide-overlay
          persistent
          max-width="600px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title class="linde-font px-3"
                >Benutzer bearbeiten</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              <v-form v-model="validEdited" ref="editForm">
                <v-container grid-list-xs>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        label="*Unternehmen"
                        required
                        :rules="rules.nameRules"
                        v-model="editItem.company"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        label="*Vorname"
                        required
                        :rules="rules.nameRules"
                        v-model="editItem.firstName"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        label="*Nachname"
                        required
                        :rules="rules.nameRules"
                        v-model="editItem.lastName"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 v-if="superUser == '1'">
                      <v-select
                        :items="['Admin', 'Verkäufer']"
                        v-model="role"
                        label="Benutzerrolle"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        label="*Email"
                        persistent-hint
                        :rules="rules.emailRules"
                        v-model.trim="editItem.email"
                        hint="An diese E-Mail wird das Initial-Passwort gesendet."
                        required
                        disabled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                          label="*Telefon"
                          persistent-hint
                          :rules="rules.phoneRules"
                          v-model.trim="editItem.phone"
                          hint="Diese wird für den Ausdruck der PDF verwendet."
                          required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-flex xs12>
                  <small>*indiziert Pflichtfelder</small>
                </v-flex>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="normalize-font mb-2"
                flat
                @click.native="editUserDialog = false"
                ><span
                  v-if="
                    (superUser && superUser === 1) ||
                      (user.isAdmin && !editItem.isAdmin)
                  "
                  >Abbrechen</span
                ><span v-else>Schließen</span></v-btn
              >
              <v-btn
                v-if="
                  (superUser && superUser == 1) ||
                    (user.isAdmin && !editItem.isAdmin)
                "
                color="primary"
                class="normalize-font mb-2 mr-2"
                @click.native="editUser()"
                >Benutzer speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import SellerService from "../../services/seller.services";
import { mapState } from "vuex";
import constants from "@/utils/constants";

export default {
  name: "user-management",
  data() {
    return {
      pagination: {
        sortBy: "id"
      },
      deleteItem: {
        firstName: "",
        lastName: "",
        id: null
      },
      editItem: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        id: null
      },
      roles: ["Admin", "Verkäufer"],
      role: "Verkäufer",
      superUser: false,
      rules: constants.formRules,
      password: null,
      valid: false,
      validEdited: false,
      deleteDialog: false,
      newUserDialog: false,
      editUserDialog: false,
      userdata: {
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        phone: null,
        emailVerified: false,
        role: null
      },
      headers: [
        {
          text: "User-ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "Unternehmen", value: "company" },
        { text: "Vorname", value: "firstName" },
        { text: "Nachname", value: "lastName" },
        { text: "Kontotyp", value: "role" },
        { text: "E-Mail", value: "email" },
        { text: "Telefon", value: "phone" },
        { text: "", value: "spacer1", sortable: false },
        { text: "", value: "spacer2", sortable: false },
        { text: "", value: "spacer3", sortable: false }
      ],
      users: []
    };
  },
  watch: {
    password: function(value) {
      this.password = value;
    },
    users: function(value) {
      this.users = value;
    }
  },
  mounted() {
    this.superUser = this.user.superUser;
    SellerService.getSellers(this.accessToken, this.user.superUser)
      .then(response => {
        this.users = response.data;
      })
      .catch(error => {
        throw error;
      });
  },
  computed: {
    ...mapState(["accessToken", "user"])
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    openDeleteDialog(item) {
      this.deleteItem = item;
      this.deleteDialog = true;
    },
    openEditUserDialog(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editUserDialog = true;
      this.role = item.isAdmin ? "Admin" : "Verkäufer";
    },
    generatePassword(length = 12) {
      let password = "",
        character;
      while (length > password.length) {
        if (
          password.indexOf(
            (character = String.fromCharCode(
              Math.floor(Math.random() * 94) + 33
            )),
            Math.floor(password.length / 94) * 94
          ) < 0
        ) {
          password += character;
        }
      }
      if (/\d/.test(password) === false) {
        password = password.replaceAt(password.length - 1, "1");
      }
      this.password = password;
    },
    addUser() {
      this.$refs.form.validate();
      if (this.valid) {
        this.userdata.username = this.userdata.email;
        this.userdata.password = this.password;
        this.userdata.isAdmin = this.role === "Admin";
        SellerService.postSeller(this.accessToken, this.userdata)
          .then(response => {
            this.$store.dispatch("showSnackbar", {
              color: "success",
              message: "Benutzer erfolgreich angelegt."
            });
            this.userdata = {
              firstName: null,
              lastName: null,
              company: null,
              email: null,
              phone: null,
              emailVerified: false
            };
            this.password = null;
            this.users.push(response.data);
            this.newUserDialog = false;
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              color: "error",
              message: "Die eingegebene Email ist bereits in Verwendung."
            });
          });
      }
    },
    editUser() {
      this.$refs.editForm.validate();
      if (this.validEdited) {
        this.userdata.username = this.userdata.email;
        this.userdata.password = this.password;
        this.userdata.isAdmin = this.role === "Admin";

        this.editItem.isAdmin = this.role === "Admin";

        SellerService.patchSeller(
          this.accessToken,
          this.editItem.id,
          this.editItem
        )
          .then(response => {
            this.$store.dispatch("showSnackbar", {
              color: "success",
              message: "Benutzer erfolgreich aktualisiert."
            });
            let index = this.users.findIndex(i => i.id === response.data.id);
            if (index > -1) {
              this.users.splice(0, 0);
              this.users[index] = response.data;
            }
            this.editUserDialog = false;
          })
          .catch(error => {
            throw error;
          });
      }
    },
    deleteUser(id) {
      axios
        .delete(
          this.$apiURL +
            "Sellers/" +
            id +
            "?access_token=" +
            (this.$store.state.accessToken
              ? this.$store.state.accessToken.id
              : null)
        )
        .then(() => {
          let index = this.users.findIndex(i => i.id === id);
          if (index > -1) {
            this.users.splice(index, 1);
          }
        })
        .catch(error => {
          throw error;
        });

      this.deleteDialog = false;
    }
    // changePasswordRequest(email) {
    // axios
    //   .post(
    //     this.$apiURL +
    //       "Sellers/reset?access_token=" +
    //       (this.$store.state.accessToken
    //          ? this.$store.state.accessToken.id
    //          : null) /*+'&email='+email*/,
    //      { email: email }
    //    )
    //    .then(() => {})
    //    .catch(error => {
    //      throw error;
    //    });
    // }
  }
};
String.prototype.replaceAt = function(index, replacement) {
  return (
    this.substring(0, index) +
    replacement +
    this.substring(index + replacement.length)
  );
};
</script>

<style scoped></style>
