<template>
  <v-container class="pa-0 ma-0 mr-0" style="max-width:100%;">
    <v-layout id="returnT-truck-manager">
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-layout text-xs-right v-if="isAdmin">
          <v-flex xs12 class="pa-0 ma-2 mr-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
            <v-btn
                small
                dark
                fab
                top
                icon
                right
                color="primary"
                @click="addReturnTruck"
                v-on="on"
            >
              <v-icon style="display: inline-flex">add</v-icon>
            </v-btn>
              </template>
              <span>Erstellen</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
        <v-card-title>
          <v-layout>
            <v-flex md3 xs12>
              <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Suche"
                  placeholder="Fahrgestell-Nr, ..."
                  single-line
                  hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-data-table
            style="padding: 0 15px!important;"
            :headers="headers"
            :search="search"
            :items="returnTrucks"
            :pagination.sync="pagination"
            :rows-per-page-items="[5, 10, 25, { text: 'Alle', value: -1 }]"
            rows-per-page-text="Zeilen pro Seite"
            class="compact-table elevation-0"
            dense
            fixed-header
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-left lower-padding">
              <v-btn
                  small
                  icon
                  flat
                  right
                  @click="
                  changeVisibility(props.item.id, props.item.active)
                "
                  color="primary"
                  :disabled="!isAdmin"
              >
                <span v-if="props.item.active === 0 || !props.item.active">
                  <v-icon small>visibility_off</v-icon>
                </span>
                <span v-if="props.item.active === 1">
                  <v-icon small>visibility</v-icon>
                </span>
              </v-btn>
            </td>
            <td class="text-xs-left">{{ props.item.id }}</td>
            <td class="text-xs-left" >{{ props.item.createdAt | formatDate }}</td>
            <td class="text-xs-left" >{{ props.item.updatedAt | formatDate }}</td>
            <td class="text-xs-left" >
              <ColoredStatusIndicator :status="props.item.status"></ColoredStatusIndicator>
            </td>
            <td class="text-xs-left" >{{ props.item.chassisNumber }}</td>
            <td class="text-xs-left" >{{ props.item.manufacturer }}</td>
            <td class="text-xs-left" >{{ props.item.type }}</td>
            <td class="text-xs-left" >{{ props.item.operatingHours }}</td>
            <td class="text-xs-left" >{{ props.item.constructionYear }}</td>
            <td class="text-xs-left" >{{ setYesOrNo(props.item.inStock) }}</td>
            <td class="text-xs-left" >{{ setYesOrNo(props.item.costEstimate) }}</td>
            <td class="text-xs-left" >{{ mapStatusOfReturnTruck(props.item.secondLifeDecision) }}</td>
            <td class="text-xs-left text-md-left actions-cell" >
              <v-layout v-if="isAdmin">
                <v-flex>
                  <v-btn
                      right
                      small
                      icon
                      flat
                      @click="editReturnTruck(props.item.id)"
                      color="primary"
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          right
                          small
                          icon
                          flat
                          @click="downloadImagesAsZip(props.item.id)"
                          color="primary"
                          v-on="on"
                      >
                        <v-icon style="display: inline-flex" small>archive</v-icon>
                      </v-btn>
                    </template>
                    <span>Bilder als Zip</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          right
                          small
                          icon
                          flat
                          @click="downloadPDF(props.item)"
                          color="primary"
                          v-on="on"
                      >
                        <v-icon style="display: inline-flex" small>print</v-icon>
                      </v-btn>
                    </template>
                    <span>PDF</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          right
                          small
                          icon
                          flat
                          @click="copyToSS24(props.item.id)"
                          color="primary"
                          v-on="on"
                          :disabled="props.item.secondLifeDecision !== 'END_CUSTOMER'"
                      >
                        <v-icon style="display: inline-flex" small>content_copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Zu SS24 kopieren</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-btn
                      right
                      small
                      icon
                      flat
                      @click="deleteReturnTruckById(props.item.id)"
                      color="error"
                  >
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout v-else>
                <v-flex>
                  <v-btn
                      right
                      small
                      icon
                      flat
                      @click="editReturnTruck(props.item.id)"
                      color="primary"
                  >
                    <v-icon small>description</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </td>
          </template>
        </v-data-table>
        <!-- DELETE DIALOG -->
        <DeleteReturnTruckDialog></DeleteReturnTruckDialog>
        <!-- CONFIRM COPY DIALOG -->
        <ConfirmCopyToMarketplaceDialog></ConfirmCopyToMarketplaceDialog>
        <!-- EDIT DIALOG -->
        <ReturnTruckForm
            v-model="dialog"
            :equipment="selectedReturnTruck"
            :showDialog="dialog"
        ></ReturnTruckForm>
        <CreateReturnTruckForm></CreateReturnTruckForm>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import ReturnTruckForm from "@/components/_returnTrucks/ReturnTruckForm.vue";
import ReturnTruckService from "@/services/return-truck.service";
import {EventBus} from "@/event-bus";
import {saveAs} from "file-saver";
import HelperFunctions from "@/utils/helper-functions";
import DeleteReturnTruckDialog from "@/components/_returnTrucks/DeleteReturnTruckDialog.vue";
import ConfirmCopyToMarketplaceDialog from "@/components/_returnTrucks/ConfirmCopyToMarketplaceDialog.vue";
import CreateReturnTruckForm from "@/components/_returnTrucks/CreateReturnTruckForm.vue";
import ColoredStatusIndicator from "@/components/shared/ColoredStatusIndicator.vue";

export default {
  name: "ReturnTruckManagement",
  data: function () {
    return {
      pagination: {
        sortBy: "id",
        rowsPerPage: 10
      },
      search: "",
      headers: [
        {text: "Aktiv", align: "left", sortable: true, value: "active", width: "1%"},
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id",
          width: "1%"
        },
        {text: "Erstelldatum", align:"left", value: "createdAt", width: "1%"},
        {text: "Aktualisiert", align:"left", value: "updatedAt", width: "1%"},
        {text: "Status", align:"left", value: "status", width: "1%"},
        {text: "Fahrgestell-Nr.", value: "chassisNumber", width: "1%"},
        {text: "Hersteller", value: "manufacturer", width: "1%"},
        {text: "Typen Beschreibung", value: "type", width: "1%"},
        {text: "Betriebstunden", value: "operatingHours", width: "1%"},
        {text: "Baujahr", value: "constructionYear", sortable: true, width: "1%"},
        {text: "Im Bestand", value: "inStock", sortable: true, width: "1%" },
        {text: "Kostenvoranschlag", value: "costEstimate", sortable: true, width: "1%"},
        {text: "Weitere Verwendung", value: "secondLifeDecision", sortable: true, width: "1%"},
        {text: "Aktionen", value: "actions", sortable: false, width: "1%"}
      ],
      dialogStatus: false,
      deleteDialog: false,
      importDialog: false,
      reservedDialog: false,
      returnTruckId: null,
      returnTruck: null,
      isAdmin: false,
      selectedReturnTruck: null,
      returnTrucks: [],
      file: "",
      response: "",
      infoColor: "primary",
    };
  },
  components: {
    ColoredStatusIndicator,
    CreateReturnTruckForm,
    ConfirmCopyToMarketplaceDialog,
    DeleteReturnTruckDialog,
    ReturnTruckForm,
  },
  mounted() {
    this.isAdmin = this.user.isAdmin;
    this.reloadReturnTrucksList();
    EventBus.$on("onCloseReturnTruckDialog", () => {
      this.selectedReturnTruck = null;
      this.reloadReturnTrucksList();
    });
    EventBus.$on("onCloseReturnTruckConfirmDialog", () => {
      this.selectedReturnTruck = null;
    });
  },
  computed: {
    ...mapState(["accessToken", "user"]),
    dialog: {
      get: function () {
        this.reloadReturnTrucksList();
        return this.dialogStatus;
      },
      set: function (value) {
        this.reloadReturnTrucksList();
        this.dialogStatus = value;
      }
    }
  },
  methods: {
    ...HelperFunctions,
    reloadReturnTrucksList() {
      ReturnTruckService.getReturnTrucks(this.accessToken).then(response => {
        this.returnTrucks = response.data;
      })
    },
    changeVisibility(id, active) {
      const visibility = active ? "inaktiv" : "aktiv";
      this.loading(true);
      ReturnTruckService.patchReturnTruckById(
          this.accessToken,
          id,
          {
            active: active === 1 ? 0 : 1
          }
      )
          .then(() => {
            this.initiateSuccessInfo("Return Truck ist nun " + visibility, true)
            this.reloadReturnTrucksList()
            this.loading(false);
          })
          .catch(() => {
            this.initiateErrorInfo("Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.")
          });
    },

    addReturnTruck() {
      EventBus.$emit("openCreateReturnTruckDialog", true);
    },
    editReturnTruck(id) {
      ReturnTruckService.getReturnTruckById(this.accessToken, id).then(response => {
        EventBus.$emit("openReturnTruckDialog", true);
        EventBus.$emit("onSelectReturnTruck", response.data);
      })
    },
    deleteReturnTruckById(id) {
      ReturnTruckService.getReturnTruckById(this.accessToken, id).then(response => {
        EventBus.$emit("openDeleteReturnTruckDialog", response.data);
      });
    },
    async downloadImagesAsZip(id) {
      try {
        const response = await ReturnTruckService.downloadImagesAsZipFile(this.accessToken, id)
        // Create a URL for the downloaded zip file
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary <a> element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'images.zip'); // Name of the downloaded file
        document.body.appendChild(link);
        link.click();

        // Clean up the URL object and remove the temporary link
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        throw error;
      }
    },
    copyToSS24(id) {
      // should open dialog with confirmation
      ReturnTruckService.getReturnTruckById(this.accessToken, id).then(response => {
        EventBus.$emit("openConfirmCopyToMarketplaceReturnTruckDialog", response.data);
      })
    },
    downloadPDF(item) {
      this.loading(true)
      ReturnTruckService.getReturnTruckPdf(this.accessToken, item.id)
          .then(response => {
            const options = {
              data: this.base64toBlob(response.data),
              name: `${item.cType}-${Date.now()}.pdf`
            };
            try {
              saveAs(options.data, options.name);
            } catch (e) {
              window.open(
                  window.URL.createObjectURL(options.data),
                  "Download",
                  "width=10,height=10,resizable=yes"
              );
            }
            this.loading( false);
          })
          .catch(error => {
            throw error;
          });
    },
  }
};
</script>

<style scoped>
/deep/ table.v-table tbody td,
/deep/ table.v-table tbody th,
/deep/ table.v-table thead td,
/deep/ table.v-table thead th {
  padding: 0 8px !important;
}
</style>
